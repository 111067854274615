<template>
  <div style="height: inherit">
    <!-- Details -->
    <section class="chat-app-window bg-white">
      <div class="h-100 p-1">
        <div class="sidebar-toggle d-block d-lg-none mr-1">
          <feather-icon
            icon="MenuIcon"
            class="cursor-pointer"
            size="21"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>
        <b-row>
          <b-col xl="3" lg="4" md="3">
            <b-form-group
              label="Select Class"
              invalid-feedback="class is required."
              ref="class"
            >
              <v-select
                ref="class"
                multiple
                :closeOnSelect="false"
                v-model="classID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="4" md="3">
            <b-form-group
              label="Select Section"
              invalid-feedback="section is required."
              ref="section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="secID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="admittedSections"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkSections()"
                ref="section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="4" md="3">
            <b-form-group invalid-feedback="Fees is required." ref="fees">
              <div class="d-flex justify-content-between">
                <label class="bv-no-focus-ring col-form-label pt-0 mr-50"
                  >Fees
                </label>
                <b-form-checkbox v-model="myObj.isAmount">
                  By Amount
                </b-form-checkbox>
              </div>
              <b-form-input
                placeholder="Enter amount"
                type="number"
                v-model="myObj.amount"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Operator"
              invalid-feedback="Operator is required."
              ref="op"
            >
              <b-button
                class="btn-icon mr-50"
                v-for="item in operators"
                :key="item.value"
                :variant="
                  myObj.operator == item.value ? 'primary' : 'outline-primary'
                "
                v-b-tooltip.hover.top
                :title="item.tooltip"
                @click="myObj.operator = item.value"
              >
                {{ item.text }}
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="3" lg="4" md="3">
            <b-form-group invalid-feedback="Increment is required." ref="inc">
              <div class="d-flex justify-content-between">
                <label class="bv-no-focus-ring col-form-label pt-0 mr-50"
                  >Increment
                </label>
                <b-form-checkbox v-model="myObj.incByAmount">
                  By Amount
                </b-form-checkbox>
              </div>
              <b-form-input
                placeholder="Enter amount"
                type="number"
                v-model="myObj.incAmount"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="4" md="5" class="mt-2">
            <b-button
              class="btn-icon mr-50"
              variant="primary"
              v-if="myObj.id == 0"
            >
              Apply Increment
            </b-button>
            <b-button class="btn-icon" variant="danger" v-else>
              Rollback
            </b-button>
          </b-col>
        </b-row>

        <b-table
          class="mt-2"
          :busy="gridLoading"
          :items="items"
          :fields="fields"
          show-empty
          responsive
          hover
          small
        >
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>

          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="data.item.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ data.item.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  GR - {{ data.item.grNo }}
                </b-badge>
                <b-badge variant="light-primary" class="ml-50">
                  F# - {{ data.item.family_code }}
                </b-badge>
              </small>
            </b-media>
          </template>

          <template #cell(oldAmount)="data">
            <b-badge variant="light-primary">
              {{ data.item.oldAmount }}
            </b-badge>
          </template>
          <template #cell(newAmount)="data">
            <b-badge variant="light-primary">
              {{ data.item.newAmount }}
            </b-badge>
          </template>
        </b-table>
      </div>
    </section>
    <!-- Details -->

    <!-- history Sidebar-->
    <portal to="content-renderer-sidebar-left">
      <div class="sidebar-left">
        <div class="sidebar">
          <div
            class="sidebar-content"
            :class="{
              show: mqShallShowLeftSidebar,
            }"
          >
            <div class="p-1 border-bottom d-flex justify-content-between">
              <h4 class="text-primary mb-0">History</h4>
              <feather-icon
                icon="XIcon"
                size="16"
                @click="mqShallShowLeftSidebar = false"
                class="cursor-pointer d-lg-none"
              />
            </div>

            <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="chat-user-list-wrapper list-group scroll-area"
            >
              <ul class="chat-users-list chat-list media-list">
                <div v-if="dataLoading">
                  <vue-content-loading
                    :width="300"
                    :height="60"
                    class="pl-1"
                    v-for="n in 5"
                    :key="n"
                  >
                    <rect x="0" y="13" rx="3" ry="3" width="250" height="13" />
                    <rect x="0" y="34" rx="3" ry="3" width="200" height="10" />
                  </vue-content-loading>
                </div>
                <template v-else>
                  <li v-for="item in historyData" :key="item.id">
                    <div class="chat-info flex-grow-1 mt-0 pl-0">
                      <h5 class="mb-0">
                        {{ item.status }}
                      </h5>
                      <p class="card-text text-truncate">
                        {{ item.monthYear }} - {{ item.percentage }} %
                      </p>
                    </div>
                    <div class="chat-meta text-nowrap">
                      <small class="float-right mb-25 chat-time">
                        {{
                          new Date(item.date).toLocaleDateString("en-UK", {
                            year: "numeric",
                            day: "numeric",
                            month: "short",
                          })
                        }}
                      </small>
                    </div>
                  </li>
                </template>
              </ul>
            </vue-perfect-scrollbar>
          </div>
        </div>
      </div>
    </portal>
    <!-- history Sidebar-->
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  VBToggle,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { VueContentLoading } from "vue-content-loading";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    VBToggle,
    BAvatar,
    vSelect,
    flatPickr,
    VuePerfectScrollbar,
    VueContentLoading,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      mqShallShowLeftSidebar: false,
      dataLoading: false,
      historyData: [
        {
          id: 1,
          status: "Increment Applied",
          date: "2023-01-04",
          percentage: 20,
          monthYear: "Feb 2023",
        },
        {
          id: 2,
          status: "Increment Applied",
          date: "2023-01-21",
          percentage: 15,
          monthYear: "April 2023",
        },
        {
          id: 3,
          status: "Increment Applied",
          date: "2023-04-15",
          percentage: 40,
          monthYear: "June 2023",
        },
        {
          id: 4,
          status: "Increment Applied",
          date: "2023-04-15",
          percentage: 35,
          monthYear: "July 2023",
        },
        {
          id: 5,
          status: "Rollback",
          date: "2023-05-08",
          percentage: 40,
          monthYear: "April 2023",
        },
      ],
      classes: [],
      classesOptions: [{ name: "All Classes", id: 0 }],
      classID: [0],
      admittedSections: [{ section: "All Sections", id: 0 }],
      secID: [0],
      gridLoading: false,
      fields: [
        { label: "Name", key: "name" },
        { label: "old amount", key: "oldAmount" },
        { label: "new amount", key: "newAmount" },
        // { key: "actions", label: "actions" },
      ],
      items: [],
      operators: [
        {
          text: ">=",
          tooltip: "Greater Than Equal",
          value: "greaterthanequal",
        },
        { text: "<=", tooltip: "Less Than Equal", value: "lessthanequal" },
        { text: "=", tooltip: "Equal", value: "equal" },
      ],
      myObj: {
        id: 0,
        clsID: 0,
        secID: 0,
        operator: "greaterthanequal",
        amount: 0,
        isAmount: false,
        campusID: this.$store.state.userData.cId,
        incByAmount: false,
        incAmount: 0,
      },
    };
  },
  created() {},
  computed: {
    currentBreakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
  },
};
</script>
<style lang=""></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
